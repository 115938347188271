import React from "react"

import { withStyles } from "@material-ui/core/styles"
import Container from "../components/Container"

const NotFoundPage = React.memo(({ classes }) => (
  <Container title="404: Não encontrado" noFooter>
    <div className={classes.notfound}>
      <div className={classes.notfound404}>
        <h3>Oops! Página não encontrada</h3>
        <h1>
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </h1>
      </div>
      <h2>Lamentamos, mas a página solicitada não foi encontrada</h2>
    </div>
  </Container>
))

const styles = {
  notfound: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    lineHeight: 1.4,
    textAlign: "center",
    position: "fixed",
  },
  notfound404: {
    position: "relative",
    height: 240,
    "& > h1": {
      fontFamily: "Montserrat, sans-serif",
      position: "absolute",
      left: "50%",
      top: "50%",
      "-webkit-transform": "translate(-50%, -50%)",
      "-ms-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
      fontSize: 252,
      fontWeight: 900,
      margin: 0,
      color: "#262626",
      textRransform: "uppercase",
      letterSpacing: -40,
      marginLeft: -20,
      "& > span": {
        textShadow: "-8px 0px 0px #fff",
      },
    },
    "& > h2": {
      fontFamily: "Cabin, sans-serif",
      fontWeight: 400,
      textTransform: "uppercase",
      color: "#000",
      marginTop: 0,
      marginBottom: 25,
    },
    "& > h3": {
      fontFamily: "Cabin, sans-serif",
      position: "relative",
      fontWeight: 700,
      textTransform: "uppercase",
      color: "#262626",
      margin: 0,
      letterSpacing: 3,
      paddingLeft: 6,
    },
  },
  "@media only screen and (max-width: 767px)": {
    notfound404: {
      height: 200,
      "& > h1": {
        fontSize: 200,
      },
    },
  },
  "@media only screen and (max-width: 480px)": {
    notfound404: {
      height: 162,
      "& > h1": {
        fontSize: 162,
        height: 150,
      },
    },
    notfound: {
      "& > h2": {
        position: "relative",
        margin: 30,
      },
    },
  },
}

export default withStyles(styles)(NotFoundPage)
